/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NotiService } from '@memberspot/frontend/shared/util/noti';
import {
  Permission,
  PermissionModule,
} from '@memberspot/shared/model/permission';
import { generalPermissionChecker } from '@memberspot/shared/util/permission';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SchoolAdminPermissionQuery } from '../../state/school-admin-permission.query';

@Injectable({
  providedIn: 'root',
})
export class GeneralPermissionGuard {
  constructor(
    private schoolPermissionQuery: SchoolAdminPermissionQuery,
    private noti: NotiService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const permissionParam = this.getGuardParam(route);

    if (!permissionParam) {
      console.error(
        `General permission guard parameter is missing for url ${state.url}`,
      );

      return of(false);
    }

    const { schoolId } = route.params;

    return this.schoolPermissionQuery.selectLoading().pipe(
      filter((loadingPermissions) => !loadingPermissions),
      switchMap(() =>
        this.schoolPermissionQuery.selectEntity((e) => e.schoolId === schoolId),
      ),
      map((p) => {
        if (
          generalPermissionChecker(permissionParam, Permission.READ, p?.role)
        ) {
          return true;
        }

        this.noti.infoIntl('noAccess.generalPermission');

        return this.router.createUrlTree([schoolId, 'courses']);
      }),
    );
  }

  private getGuardParam(route: ActivatedRouteSnapshot): PermissionModule {
    return route.data?.['neededPermission'] as PermissionModule;
  }
}
